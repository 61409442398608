@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;

        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;

        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;

        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;

        --border: 216 34% 17%;
        --input: 216 34% 17%;

        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;

        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;

        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;

        --ring: 216 34% 17%;

        --radius: 0.5rem;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
        font-feature-settings:
            "rlig" 1,
            "calt" 1;
    }
}

@layer utilities {
    /* For Webkit-based browsers (Chrome, Safari and Opera) */
    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }

    /* For IE, Edge and Firefox */
    .scrollbar-hide {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .mg-handler-circle-shadow {
        @apply shadow-[0px_0px_2px_rgba(0,0,0,0.8)];
    }
}

@layer components {
    /* .my-react-select-container {
    } */
    .my-react-select-container .my-react-select__control {
        @apply cursor-pointer bg-white dark:bg-darkSurface-200 rounded-lg border border-irGray-400 dark:border-darkSurface-100 dark:hover:border-neutral-500 h-12;
    }

    .my-react-select-container .my-react-select__control--is-focused {
        @apply border-irGray-400 dark:border-darkSurface-100 shadow-none;
    }

    .my-react-select-container .my-react-select__menu {
        @apply bg-white dark:bg-neutral-700 border border-irGray-200 dark:border-neutral-600;
    }

    .my-react-select-container .my-react-select__option {
        @apply text-black dark:text-white bg-white hover:bg-irBlue-100 dark:bg-darkSurface-200 dark:hover:bg-darkSurface-100;
    }

    .my-react-select-container .my-react-select__option--is-selected {
        /* @apply text-black dark:text-white bg-white hover:bg-irBlue-100 dark:bg-darkSurface-200 dark:hover:bg-darkSurface-100; */
        @apply bg-irBlue-200 hover:bg-irBlue-200 dark:bg-darkSurface-100 dark:hover:bg-darkSurface-100 text-white;
    }

    /* .my-react-select-container .my-react-select__option--is-focused {
      @apply bg-neutral-200 dark:bg-neutral-800;
    } */

    .my-react-select-container .my-react-select__indicator-separator {
        @apply hidden;
    }

    .my-react-select-container .my-react-select__input-container,
    .my-react-select-container .my-react-select__placeholder,
    .my-react-select-container .my-react-select__single-value {
        @apply text-neutral-600 dark:text-neutral-200;
    }
}

:root {
    --dark-blue: #5e86dd;
    --blue: #6e99f4;
    --light-blue: #e0eaff;
    --light-gray: #f9f9f9;
    --gray: #f0f0f0;
    --dark-gray: #2d2d2d40;
    --darker-gray: #9a9a9a;
    --red-500: #ef4444;
    --black: #1a1a1a;
    --red-50: #ea99a2;
    --red-400: #db1436;
    --yellow-200: #edd785;
    --green-400: #21d36d;
    --green-300: #8be98c;

    --blue-100: #e0eaff;
    --blue-200: #6e99f4;
    --blue-400: #5e86dd;
    --blue-600: #5c85dc;

    /** CSS DARK THEME PRIMARY COLORS */
    --dark-primary-100: #6e99f3;
    --dark-primary-200: #81a4f5;
    --dark-primary-300: #93aff6;
    --dark-primary-400: #a4baf8;
    --dark-primary-500: #b4c5f9;
    --dark-primary-600: #c3d0fb;
    /** CSS DARK THEME MIXED SURFACE COLORS */
    --dark-surface-100: #1c1e25;
    --dark-surface-200: #313339;
    --dark-surface-300: #47494f;
    --dark-surface-400: #5f6066;
    --dark-surface-500: #77797e;
    --dark-surface-600: #919296;
}

html,
body {
    padding: 0;
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif; */
    overflow-y: auto !important;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    font-family: "Source Sans Pro", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.bg-blue {
    background-color: var(--blue);
}

.gray {
    color: var(--gray);
}

.bg-dark-gray {
    background-color: var(--dark-gray);
}

.bg-gray {
    background-color: var(--gray);
}

.text-blue {
    color: var(--blue);
}

.border-blue {
    border-color: var(--blue);
}

.scroll {
    overflow-y: auto;
}

.scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: var(--dark-gray);
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}

.scroll:hover::-webkit-scrollbar-thumb {
    background: var(--darker-gray);
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    background: lightgray;
}

.flipped {
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}

html {
    -webkit-tap-highlight-color: transparent;
}

div {
    outline-style: none;
}

body {
    overscroll-behavior-y: none;
}

.custom-cursor {
    cursor:
        url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMmM1LjUxNCAwIDEwIDQuNDg2IDEwIDEwcy00LjQ4NiAxMC0xMCAxMC0xMC00LjQ4Ni0xMC0xMCA0LjQ4Ni0xMCAxMC0xMHptMC0yYy02LjYyNyAwLTEyIDUuMzczLTEyIDEyczUuMzczIDEyIDEyIDEyIDEyLTUuMzczIDEyLTEyLTUuMzczLTEyLTEyLTEyem02IDEzaC01djVoLTJ2LTVoLTV2LTJoNXYtNWgydjVoNXYyeiIvPjwvc3ZnPg==)
            12 12,
        crosshair;
}

.sub-heading {
    font-size: 1.375rem;
}

.max-w-1440 {
    max-width: 1440px;
    margin: 0 auto;
}

@media only screen and (min-width: 1600px) {
    .page-shadow {
        box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.08);
    }
}

.border-x {
    border-left: 1px solid var(--gray);
    border-right: 1px solid var(--gray);
}

@media only screen and (max-width: 1440px) {
    .border-x {
        border-left: 0px;
        border-right: 0px;
    }
}

@media (prefers-color-scheme: dark) {
    .border-x {
        border-left: 1px solid var(--dark-surface-200);
        border-right: 1px solid var(--dark-surface-200);
    }
}

.leading-4-5 {
    line-height: 1.125rem;
}

.min-w-728-pc {
    width: 728px;
}

@media only screen and (min-width: 1024px) {
    .min-w-728-pc {
        min-width: 728px;
    }
}

.text-16 {
    font-size: 16px;
}
.text-14 {
    font-size: 14px;
}
.text-22 {
    font-size: 22px;
}
.text-24 {
    font-size: 24px;
}

.compressor-list-image-min-height {
    min-height: 180px !important;
}

:root {
    --mg-border-color: rgba(255, 255, 255);
    --mg-border-dash-color: #8b3eff;
    --mg-border-width: 2px;
    --mg-border-stroke: 5px;
}

.mg-textBox-border {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23000000FF' stroke-width='4' stroke-dasharray='5%2c 7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"),
        url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='4' stroke-dasharray='0' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    background-origin: border-box;
}

.text-ir-primary {
    color: #6e99f4;
}
.bg-ir-primary {
    background-color: #6e99f4;
}

iframe {
    color-scheme: normal;
}
